.App {
  text-align: center;
  animation: .7s ease-out slideInFromLeft;
}
@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
button:focus{
  outline: 0;
}


body{
  background-image: linear-gradient(90deg,rgba(149, 187, 233,.8),rgba(241, 184, 210,.74),rgba(149, 187, 233,.8));
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.hidden{
  opacity: 0;
  transition: all 1s;
  transform: translateX(-100%);
}
.show{
  opacity: 1;
  transform: translateX(0);
  translate: all 1s ease;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.moreButton{
  background-color: inherit;
  color:#fff;
  font-size: large;
  border: none;
  font-weight: 900;
  cursor: pointer;
  width: fit-content;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
