.firstPage{
    background-color: #fff;
    width: 90%;
    min-height: 90vh;
    margin: auto;
    border-radius: 30px;
    position: relative;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.navb{
    position: absolute;
    top: 2%;
    width: inherit;
}
.intro{
    text-align: start;
    font-size: larger;
    letter-spacing: .5px;
    font-weight: 400;
}
.name{
    font-size: x-large;
    letter-spacing: 1px;
    font-weight: 500;
}
.image{
    width: 60%;
    border-radius: 50%;
}
.description{
    text-align: start;
    font-size: x-large;
    line-height: 1.5em;
    letter-spacing: .5px;
    font-weight: 400;
    opacity: .6;
}
.icons{
    text-align: start;
}
.icons i{
    font-size: x-large;
    cursor: pointer;
    background: -moz-linear-gradient(120deg,rgba(149, 187, 233,.8),rgba(241, 184, 210,.74));
    background: -webkit-linear-gradient(120deg,rgba(149, 187, 233,.8),rgba(241, 184, 210,.74));
    background: linear-gradient(120deg,rgba(149, 187, 233,.8),rgba(241, 184, 210,.74));
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.btn1{
    width: 14rem;
    height: 2.5em;
    background-color: #fff;
    color: #F1B8D2;
    border: 1px solid #F1B8D2;
    border-radius: 10px;
    cursor: pointer;
    transition-duration: 0.3s;
}
.btn1:hover{
    background: -moz-linear-gradient(120deg,rgba(149, 187, 233,.8),rgba(241, 184, 210,.74));
    background: -webkit-linear-gradient(120deg,rgba(149, 187, 233,.8),rgba(241, 184, 210,.74));
    background: linear-gradient(120deg,rgba(149, 187, 233,.8),rgba(241, 184, 210,.74));
    color: #fff;
    border: none;
}
.btn2{
    width: 14em;
    height: 2.5em;
    transition-duration: 0.3s;
    border: none;
    background: -moz-linear-gradient(120deg,rgba(149, 187, 233,.8),rgba(241, 184, 210,.74));
    background: -webkit-linear-gradient(120deg,rgba(149, 187, 233,.8),rgba(241, 184, 210,.74));
    background: linear-gradient(120deg,rgba(149, 187, 233,.8),rgba(241, 184, 210,.74));
    color: #fff;
    border-radius: 10px;
    cursor: pointer;
}
.btn2:hover{
    border: 1px solid #F1B8D2;
    background: #fff;
    color: #F1B8D2;
}
.buttons{
    text-align: start;
}
.me{
    width: 15%;
    border-radius: 50%;
  }
.navbar-brand{
    text-align: start;
  }
@media only screen and (max-width: 992px) {
    .first{
        flex-direction: column-reverse;
    }
    .btn1, .btn2{
        width: 49%;
    }
    .navbar-collapse{
        text-align: start;
        background-color: #fff;
        z-index: 2;
    }
    .navbar-toggler{
        outline: none;
    }
    .description{
        font-weight: 400;
        opacity: .6;
    }
    .image{
        width: 50%;
    }
    
}
@media only screen and (max-width: 768px){
    .image{
        width: 70%;
    }
    .btn1, .btn2{
        width: 100%;
    }
    .navbar-brand{
        width: 13%;
      }
      .me{
        width: 100%;
        border-radius: 50%;
      }
    .intro{
        text-align: start;
        font-size: large;
    }
    .description{
        font-size: large;
    }
}
@media screen and (min-width: 992px){
    .first{
        height: 100%;
    }
    .firstPage{
        height: 90vh;
    }
    .navb{
        right: 5%;
    }
}