.projectImg{
    width: 90%;
    border-radius: 10px;
}
.project{
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.portfolio{
    width: 70%;
}
.moreProjectsHide{
    visibility: none;
    opacity: 0;
    height: 0;
    transform: translateX(-50%);
}
.moreProjects{
    opacity: 1;
    height: auto;
    visibility: visible;
    transform: translateX(0);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    transition: all 1s ease;
}
.projectText{
    text-align: start;
    font-size: large;
    line-height: 1.6;
    opacity: 0.7;
}
.projectIntro{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.projectTitle{
    text-align: start;
    font-size: x-large;
    font-weight: 600;
}
.projectButton1{
    width: 14rem;
    height: 2.5em;
    border: none;
    background-color: #fff;
    color: #F1B8D2;
    border: 1px solid #F1B8D2;
    border-radius: 10px;
    transition-duration: 0.3s;
    cursor: pointer;
}
.projectButton1:hover{
    border: none;
    background: -moz-linear-gradient(120deg,rgba(149, 187, 233,.8),rgba(241, 184, 210,.74));
    background: -webkit-linear-gradient(120deg,rgba(149, 187, 233,.8),rgba(241, 184, 210,.74));
    background: linear-gradient(120deg,rgba(149, 187, 233,.8),rgba(241, 184, 210,.74));
    color: #fff;
}
.projectButton2{
    width: 14em;
    height: 2.5em;
    border: none;
    background: -moz-linear-gradient(120deg,rgba(149, 187, 233,.8),rgba(241, 184, 210,.74));
    background: -webkit-linear-gradient(120deg,rgba(149, 187, 233,.8),rgba(241, 184, 210,.74));
    background: linear-gradient(120deg,rgba(149, 187, 233,.8),rgba(241, 184, 210,.74));
    color: #fff;
    transition-duration: 0.3s;
    border-radius: 10px;
    cursor: pointer;
}
.projectButton2:hover{
    border: 1px solid #F1B8D2;
    background: #fff;
    color: #F1B8D2;
}
@media only screen and (max-width: 992px) {
    .projectDiv{
        flex-direction: column-reverse;
    }
    .portfolio{
        width: 90%;
    }
    .certifications{
        width: 90%;
    }
    .projectButton1, .projectButton2{
        width: 48%;
    }
}
@media only screen and (max-width: 768px){
    .projectButton1, .projectButton2{
        width: 100%;
    }
    .projectText{
        text-align: start;
        font-size: 17px;
        line-height: 1.6;
        opacity: 0.7;
    }
}
@media screen and (min-width: 992px){
    
}