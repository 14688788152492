.music-frame{
    width: 100%;
    height: 200px;
}
#music{
    width: 90%;
}

@media screen and (min-width: 768px) {
    .music-frame{
        width: 47%;
        height: 200px;
    }
}

@media screen and (min-width: 992px) {
    .music-frame{
        width: 45%;
        height: 250px;
    }
}